<template>
	<div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px;"/>
		        <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24">
						<a-form-item required :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('Name')" class="body">
							<a-input v-model="entity.name" class="input"></a-input>
						</a-form-item>
				    </a-col>
					<a-col :span="24">
					    <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('Code')" class="body">
					    	<a-input v-model="entity.code" class="input"></a-input>
					    </a-form-item>
					</a-col>
					<a-col :span="24">
					    <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 9 }" :label="l('pid')">
							<a-tree-select
							        v-model="pId"
							        style="width: 200px"
							        :tree-data="treeDataList"
							        :replace-fields="treeReplaceFields"
							        :dropdown-style="{ maxHeight: '400px', overflow: 'auto',width: '300px' }"
							        :placeholder="l('please_select_the_superior_node')"
							        allow-clear
									:dropdownMatchSelectWidth="false"
							        tree-default-expand-all
							>
							</a-tree-select>
					    </a-form-item>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer" v-if="!isLook">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</template>

<script>
	import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
	import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
	import { AuditItemtypeServiceProxy } from '../../../../shared/service-proxies';
	
	let _this;
	export default {
		name: 'new_audit_item',
		mixins: [ModalComponentBase],
		components: {
		    
		},
		created() {
		    this.fullData(); // 模态框必须,填充数据到data字段
		    this.AuditItemtypeServiceProxy = new AuditItemtypeServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
		    this.getData();
			this.getTreeData();
		},
		data() {
		    return {
				zh_CN,
		        isEdit: false,//是否是编辑
		        isLook: false,//是否是查看
		        entity: [],
				pId: undefined,
				treeDataList: [],
				treeReplaceFields: {
				    title: 'name',
				    key: 'id',
				    value: 'id'
				},
				isclick: true,
		    }
		},
		methods: {
			getData() {
				this.isLoading = true;
				this.AuditItemtypeServiceProxy.getForEdit(
				this.id
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					console.log(res);
					this.entity = res;
					console.log(this.entity.code);
					if(this.entity.pId==0){
						this.pId = undefined;
					}else {
						this.pId=res.pId;
					}
					console.log(this.pId);
				})
			},
			getTreeData() {
			    this.isLoading = true;
			    this.AuditItemtypeServiceProxy.getAllDate(
			    )
			        .finally(() => {
			            this.isLoading = false;
			        })
			        .then((res) => {
			            console.log(res);
			            this.treeDataList = JSON.parse(JSON.stringify(res));
			        })
			},
			save() {
			    if (!this.entity.name) {
			        abp.message.info('分类名称不能为空');
			        return;
			    }
				if (this.isEdit==true){
				  if (this.pId==this.id){
					abp.message.info(this.l('You_cannot_choose_yourself_as_the_superior_node'));
					return;
				  }
				}
				if(this.pId==undefined) {
					this.entity.pId = 0;
				}else {
					this.entity.pId = this.pId;
				}
				var auditItemTypeEditDto = {auditItemTypeEditDto:this.entity};
				// console.log(auditItemTypeEditDto);
				if(this.isclick) {
					this.isclick = false;
					this.AuditItemtypeServiceProxy.createOrUpdate(
							auditItemTypeEditDto
							).finally(() => {
					            this.close()
					        }).then(res => {
								this.isclick = true;
					            this.success(true)
					        })
				}
			},
			
		}
	}
</script>

<style>
	.input {
		width: 200px;
	}
	
	.body {
		margin-bottom: 10px;
	}
</style>
